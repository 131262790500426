import React from "react";
import Image from "gatsby-image";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import Container from "components/Container";
import Intro from "components/Intro";
import { SEO } from "components/SEO";

const QualityPage = ({ data }) => {
	return (
		<Layout pageName="quality">
			<SEO
				title="Quality Objective"
				description="Measurements taken by Blue Medical to ensure product and service quality as well as patient saftey."
			/>
			<Intro className="quality-intro">
				<h1 className="mono">
					Built on a long-standing proven Quality System
				</h1>
				<p>We never compromise on quality and safety.</p>
			</Intro>
			<Container sectionName="quality-info">
				<div className="quality-info__image">
					<Image
						fluid={data.expertiseOne.childImageSharp.fluid}
						alt="Medical devices being sterilized."
					/>
				</div>
				<div className="quality-info__text copy-large">
					<p className="quality-info__copy copy-large">
						The objective of Blue Medical is to supply products and
						services of uncompromising quality by complying with the
						regulatory requirements of ISO 13485:2016 and adhering
						to strict quality assurance principles.
					</p>
				</div>
				<div className="quality-info__image">
					<Image
						fluid={data.expertiseTwo.childImageSharp.fluid}
						alt="Blue Medical employee packaging medical devices in cleanroom."
					/>
				</div>
			</Container>
			<Container sectionName="quality quality-cleanroom">
				<div className="cleanroom__heading">
					<h2>Patient safety is the highest priority</h2>
				</div>
				<div className="cleanroom__info">
					<p>
						Blue Medical cleaning process includes both the
						preliminary and final cleaning, disinfection and drying
						of the medical equipment. The optimal cleaning process
						is determined in cooperation with the customer. All
						steps of the cleaning process are fully documented and
						traceable.
					</p>
					<div className="cleanrooms">
						<h2>Cleanroom (class ISO 7)</h2>
						<p>
							Primary packaging takes place in our clean room
							(class ISO 7) with qualified and constantly
							monitored equipment. Our qualified staff ensures
							full traceability of the products during the entire
							process.
						</p>
						<h2>Cleanroom (class ISO 8)</h2>
						<p>
							Assembly and final packaging (class ISO 8) Finished
							primary packaged medical devices are then assembled
							into final package (e.g. with instructions for use,
							patient labels, etc.). The finished packs are then
							palletized in sterile boxes according to the loading
							plan, protected for transport and sent for
							sterilization.
						</p>
					</div>
				</div>
				<div className="certification-wrapper">
					<div className="certification">
						{/* <CertLogo alt="ISO Certification Logo" /> */}
						<h2 className="certification__text copy-large">
							Certified by EN ISO 13485:2016 <br />
							Quality management for medical devices
						</h2>
					</div>
				</div>
			</Container>
		</Layout>
	);
};

export default QualityPage;

export const pageQuery = graphql`
	query {
		expertiseOne: file(
			relativePath: { eq: "quality/BM_Quality_1_2928x960.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 1464) {
					...GatsbyImageSharpFluid
				}
			}
		}
		expertiseTwo: file(
			relativePath: { eq: "quality/BM_Quality_2_2928x960.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 1464) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
